<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-title class="mb-1">
          {{ title }}
        </b-card-title>
        <b-card-sub-title>
          {{ subtitle }}
        </b-card-sub-title>
      </div>
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        type="bar"
        height="400"
        :options="graphData.chartOptions"
        :series="graphData.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import flatPickr from 'vue-flatpickr-component'
import apexChatData from './apexChartData'

export default {
  components: {
    BCard,
    VueApexCharts,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    flatPickr,
  },
  props: {
    chartData: {
      type: Object,
      default: () => apexChatData,
    },
    title: {
      type: String,
      default: () => '',
    },
    subtitle: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      graphData: this.chartData,
      rangePicker: ['2019-05-01', '2019-05-10'],
      chartColors: {
        column: {
          series1: '#826af9',
          series2: '#d2b0ff',
          bg: '#f8d3ff',
        },
        success: {
          shade_100: '#7eefc7',
          shade_200: '#06774f',
        },
        donut: {
          series1: '#ffe700',
          series2: '#00d4bd',
          series3: '#826bf8',
          series4: '#2b9bf4',
          series5: '#FFA1A1',
        },
        area: {
          series3: '#a4f8cd',
          series2: '#60f2ca',
          series1: '#2bdac7',
        },
      },
    }
  },
}
</script>
