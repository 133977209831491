<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col
        lg="3"
        xl="3"
        md="3"
        xs="3"
      >
        <b-card>
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                :variant="'light-info'"
              >
                <feather-icon
                  size="24"
                  :icon="'UsersIcon'"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                สมาชิก
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ numberWithCommas(data.memberCount || 0).replace('.00', '') }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-card>
      </b-col>
      <b-col
        lg="3"
        xl="3"
        md="3"
        xs="3"
      >
        <b-card>
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                :variant="'light-success'"
              >
                <feather-icon
                  size="24"
                  :icon="'DollarSignIcon'"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                เครดิตคงเหลือ
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ numberWithCommas(data.remainingCredit || 0) }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-card>
      </b-col>
      <b-col
        lg="3"
        xl="3"
        md="3"
        xs="3"
      >
        <b-card>
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                :variant="'light-primary'"
              >
                <feather-icon
                  size="24"
                  :icon="'DollarSignIcon'"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                เครดิตลูกค้า
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ numberWithCommas(data.remainingWallet || 0) }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        lg="3"
        xl="3"
        md="3"
        xs="3"
      >
        <b-card>
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                :variant="'light-success'"
              >
                <feather-icon
                  size="24"
                  :icon="'DollarSignIcon'"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                ฝากรวม
              </h4>
              <b-card-text class="font-small-3 mb-0">
                เดื่อนนี้
              </b-card-text>
              <b-card-text class="font-small-3 mb-0">
                {{ numberWithCommas(data.mTotalDeposit || 0) }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-card>
      </b-col>
      <b-col
        lg="3"
        xl="3"
        md="3"
        xs="3"
      >
        <b-card>
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                :variant="'light-danger'"
              >
                <feather-icon
                  size="24"
                  :icon="'DollarSignIcon'"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                ถอนรวม
              </h4>
              <b-card-text class="font-small-3 mb-0">
                เดือนนี้
              </b-card-text>
              <b-card-text class="font-small-3 mb-0">
                {{ numberWithCommas(data.mTotalWithdraw || 0) }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-card>
      </b-col>
      <b-col
        lg="3"
        xl="3"
        md="3"
        xs="3"
      >
        <b-card>
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                :variant="'light-danger'"
              >
                <feather-icon
                  size="24"
                  :icon="'GiftIcon'"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                โปรโมชั่น
              </h4>
              <b-card-text class="font-small-3 mb-0">
                เดือนนี้
              </b-card-text>
              <b-card-text class="font-small-3 mb-0">
                {{ numberWithCommas(data.mTotalPromotion || 0) }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-card>
      </b-col>
      <b-col
        lg="3"
        xl="3"
        md="3"
        xs="3"
      >
        <b-card>
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                :variant="'light-primary'"
              >
                <feather-icon
                  size="24"
                  :icon="'DollarSignIcon'"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                วินลอส
              </h4>
              <b-card-text class="font-small-3 mb-0">
                เดือนนี้
              </b-card-text>
              <b-card-text class="font-small-3 mb-0">
                {{ numberWithCommas(data.mTotalWinloss || 0) }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <b-form-group
          label="วันที่"
          label-for="mc-first-name"
        >
          <b-input-group>
            <flat-pickr
              v-model="selectedDate"
              class="form-control"
              label="ช่วงเวลา"
              :config="{ enableTime: false, dateFormat: 'Y-m-d', mode: 'range' }"
            />
            <b-input-group-append is-text>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="primary"
                @click="fetchData()"
              >
                ดูรายงาน
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        lg="3"
        xl="3"
        md="3"
        xs="3"
      >
        <b-card>
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                :variant="'light-success'"
              >
                <feather-icon
                  size="24"
                  :icon="'DollarSignIcon'"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                ฝากรวม
              </h4>
              <b-card-text class="font-small-3 mb-0">
                วันที่เลือก
              </b-card-text>
              <b-card-text class="font-small-3 mb-0">
                {{ numberWithCommas(data.sTotalDeposit || 0) }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-card>
      </b-col>
      <b-col
        lg="3"
        xl="3"
        md="3"
        xs="3"
      >
        <b-card>
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                :variant="'light-danger'"
              >
                <feather-icon
                  size="24"
                  :icon="'DollarSignIcon'"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                ถอนรวม
              </h4>
              <b-card-text class="font-small-3 mb-0">
                วันที่เลือก
              </b-card-text>
              <b-card-text class="font-small-3 mb-0">
                {{ numberWithCommas(data.sTotalWithdraw || 0) }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-card>
      </b-col>
      <b-col
        lg="3"
        xl="3"
        md="3"
        xs="3"
      >
        <b-card>
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                :variant="'light-danger'"
              >
                <feather-icon
                  size="24"
                  :icon="'GiftIcon'"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                โปรโมชั่น
              </h4>
              <b-card-text class="font-small-3 mb-0">
                วันที่เลือก
              </b-card-text>
              <b-card-text class="font-small-3 mb-0">
                {{ numberWithCommas(data.sTotalPromotion || 0) }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-card>
      </b-col>
      <b-col
        lg="3"
        xl="3"
        md="3"
        xs="3"
      >
        <b-card>
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                :variant="'light-primary'"
              >
                <feather-icon
                  size="24"
                  :icon="'DollarSignIcon'"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                วินลอส
              </h4>
              <b-card-text class="font-small-3 mb-0">
                วันที่เลือก
              </b-card-text>
              <b-card-text class="font-small-3 mb-0">
                {{ numberWithCommas(data.sTotalWinloss || 0) }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        lg="6"
        xl="6"
        md="6"
        xs="6"
      >
        <apex-line-area-chart title="รายงานสมาชิก" subtitle="ตามประเภท" :chart-data="graphMembr1" />
      </b-col>
      <b-col
        lg="6"
        xl="6"
        md="6"
        xs="6"
      >
        <apex-line-area-chart title="สมาชิกใหม่" subtitle="ตามช่องทางการสมัคร" :chart-data="graphMembr2" />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BCard, BMediaAside, BCardText, BMedia, BMediaBody, BAvatar, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import OverallStatistics from './components/OverallStatistics.vue'
import ApexLineAreaChart from './components/ApexLineAreaChart.vue'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    OverallStatistics,
    flatPickr,
    BFormGroup,
    BMediaAside,
    BMedia,
    BMediaBody,
    BCardText,
    BAvatar,
    ApexLineAreaChart,
    BInputGroup,
    BInputGroupAppend,
  },
  data() {
    return {
      selectedDate: null,
      data: {
      },
      graphMembr1: {
        series: [],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
            stacked: true,
          },
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: false,
            curve: 'straight',
          },
          legend: {
            show: true,
            position: 'top',
            horizontalAlign: 'left',
            fontSize: '14px',
            fontFamily: 'Montserrat',
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          xaxis: {
            categories: [
              '00:00',
              '01:00',
              '02:00',
              '03:00',
              '04:00',
              '05:00',
              '06:00',
              '07:00',
              '08:00',
              '09:00',
              '10:00',
              '11:00',
              '12:00',
              '13:00',
              '14:00',
              '15:00',
              '16:00',
              '17:00',
              '18:00',
              '19:00',
              '20:00',
              '21:00',
              '22:00',
              '23:00',
            ],
          },
          yaxis: {
          },
          fill: {
            opacity: 1,
            type: 'solid',
          },
          tooltip: {
            shared: false,
          },
          colors: ['#ff0000', '#ffa000', '#a3fffd', '#f4f8cd'],
        },
      },
      graphMembr2: {
        series: [],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
            stacked: true,
          },
          plotOptions: {
            bar: {
              horizontal: true,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: false,
            curve: 'straight',
          },
          legend: {
            show: true,
            position: 'top',
            horizontalAlign: 'left',
            fontSize: '14px',
            fontFamily: 'Montserrat',
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          xaxis: {
            categories: [
              '00:00',
              '01:00',
              '02:00',
              '03:00',
              '04:00',
              '05:00',
              '06:00',
              '07:00',
              '08:00',
              '09:00',
              '10:00',
              '11:00',
              '12:00',
              '13:00',
              '14:00',
              '15:00',
              '16:00',
              '17:00',
              '18:00',
              '19:00',
              '20:00',
              '21:00',
              '22:00',
              '23:00',
            ],
          },
          yaxis: {
          },
          fill: {
            opacity: 1,
            type: 'solid',
          },
          tooltip: {
            shared: false,
          },
          colors: ['#d61423', '#7314b5', '#7ceb5c', '#34b6d1', '#c938c7', '#d9970f'],
        },
      },
    }
  },
  created() {
    this.selectedDate = moment().format('YYYY-MM-DD')
    // data
    this.fetchData()
  },
  methods: {
    async fetchData() {
      //
      this.$http.get(`/dashboard?date=${this.selectedDate.replace(' to ', ',')}`)
        .then(response => {
          this.data = response.data.data
          this.graphMembr1.series = this.data.memberReport1
          this.graphMembr2.series = this.data.memberReport2
        })
    },
  },
  // watch: {
  //   selectedDate(val, old) {
  //     console.log('selectedDate', val, old)
  //   },
  // },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.mt-10 {
  margin-top: 20px;
}
.text-right {
  text-align: right;
}
</style>
